import avatar1 from "../assets/images/users/avatar-1.jpg";
import avatar2 from "../assets/images/users/avatar-2.jpg";
import avatar3 from "../assets/images/users/avatar-3.jpg";
import avatar4 from "../assets/images/users/avatar-4.jpg";
import avatar5 from "../assets/images/users/avatar-5.jpg";
import avatar6 from "../assets/images/users/avatar-3.jpg";
import avatar7 from "../assets/images/users/avatar-3.jpg";
import avatar8 from "../assets/images/users/avatar-3.jpg";
import avatar9 from "../assets/images/users/avatar-3.jpg";
import avatar10 from "../assets/images/users/avatar-3.jpg";

export interface CallItem {
  callId: string | number;
  firstName: string;
  lastName: string;
  profileImage?: string;
  callDuration: string;
  isIncomming: boolean;
  callDate: string;
  isGrouped: boolean;
  hasVideoCall: boolean;
  peoplesAvailableOnCall: number;
  peoples?: Array<any>;
}
const calls: CallItem[] = [
  {
    callId: 0,
    firstName: "Alexander",
    lastName: "Karanja",
    profileImage: avatar1,
    callDuration: "5:37",
    isIncomming: true,
    callDate: "2016-05-05T06:21:22",
    isGrouped: false,
    hasVideoCall: true,
    peoplesAvailableOnCall: 2,
  },
  {
    callId: 1,
    firstName: "Project",
    lastName: "Manager",
    profileImage: avatar2,
    callDuration: "5:24",
    isIncomming: true,
    callDate: "2014-05-17T10:22:03",
    isGrouped: false,
    hasVideoCall: false,
    peoplesAvailableOnCall: 2,
  },
  {
    callId: 2,
    firstName: "HotArctic",
    lastName: "HVAC",
    profileImage: avatar3,
    callDuration: "2:43",
    isIncomming: false,
    callDate: "2020-11-27T04:11:34",
    isGrouped: false,
    hasVideoCall: true,
    peoplesAvailableOnCall: 5,
    peoples: [
      {
        id: 1,
        firstName: "Alexander",
        lastName: "Karanja",
      },
      {
        id: 1,
        firstName: "Sharon",
        lastName: "Manga",
        profileImage: avatar5,
      },
      {
        id: 1,
        firstName: "Martin",
        lastName: "Njoroge",
        profileImage: avatar5,
      },

      {
        id: 1,
        firstName: "Chris",
        lastName: "Kamau",
        profileImage: avatar5,
      },
    ],
  },
  {
    callId: 3,
    firstName: "Jumla",
    lastName: "Cuts",
    callDuration: "2:41",
    isIncomming: false,
    callDate: "2019-08-29T09:52:06",
    isGrouped: true,
    hasVideoCall: true,
    peoplesAvailableOnCall: 6,
    peoples: [
      {
        id: 1,
        firstName: "Kevin",
        lastName: "Chira",
        profileImage: avatar5,
      },
      {
        id: 1,
        firstName: "Alexander",
        lastName: "Karanja",
        profileImage: avatar1,
      },
    ],
  },
  {
    callId: 4,
    firstName: "Arc",
    lastName: "org",
    callDuration: "1:57",
    isIncomming: false,
    callDate: "2018-12-23T06:05:34",
    isGrouped: true,
    hasVideoCall: false,
    peoplesAvailableOnCall: 5,
    peoples: [
      {
        id: 1,
        firstName: "Alexander",
        lastName: "Karanja",
        profileImage: avatar5,
      },
      {
        id: 1,
        firstName: "Celine",
        lastName: "Sera",
        profileImage: avatar5,
      },
    ],
  },
  {
    callId: 5,
    firstName: "John",
    lastName: "Njoroge",
    callDuration: "5:24",
    isIncomming: true,
    callDate: "2021-05-17T10:22:03",
    isGrouped: false,
    hasVideoCall: false,
    peoplesAvailableOnCall: 2,
  },
];

export { calls };
