import React from "react";
import { Button, Col, Row } from "reactstrap";
import authImage from "./../../../assets/images/blacklogo.png";

const Welcome = () => {
  return (
    <React.Fragment>
      <div className="chat-welcome-section">
        <Row className="w-100 justify-content-center">
          <Col xxl={5} md={7}>
            <div className="p-4 text-center">
              <div className="mx-auto mb-4">
                <img height="150px" src={authImage} alt="Techkey Logo" />
              </div>
              <h4>Welcome to CHATRIC</h4>
              <p className="text-muted mb-4">
                CHATRIC is a chat application design owned by{" "}
                <a target={"_blank"} href="https://ctechkey.com">
                  {" "}
                  Ctechkey
                </a>{" "}
                a department of Techkey Cybernetics. It showcases a visual
                proposal and demo functionality of what our engineers can
                develop using our tool STACK. (NextJS, Socket.io, Mongo)
              </p>
              <p className="text mb-4">
                Click on a user profile to get started
              </p>
              <a
                href="https://techkey.co.ke"
                target={"_blank"}
                className="btn btn-primary w-lg"
              >
                &copy; Techkey Cybernetics
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Welcome;
