export interface BookMarkTypes {
  id: number;
  icon: string;
  title: string;
  desc: string;
}

let bookmarks: BookMarkTypes[] = [
  {
    id: 1,
    icon: "bx bx-pin",
    title: "Techkey Website",
    desc: "https://techkey.co.ke/",
  },
  {
    id: 2,
    icon: "bx bx-pin",
    title: "HVAC company",
    desc: "https://hotarctic.co.ke/",
  },
];

const onChangeBookmark = (newData: BookMarkTypes[]) => {
  bookmarks = newData;
};

export { bookmarks, onChangeBookmark };
